.topSection {
  align-items: center;
  display: flex;
}

.form {
	margin-top: 1.5em;
  text-align: center;
}

.form a {
  text-decoration: none;
  color: #000000;
}

.loginBtn {
  width: 100%;
  margin-top: 16px; 
	border-radius: 8px;
	font-weight: 500;
}

.forgotPwdBtn {
  width: 100%;
  margin-top: 10px;
  cursor: pointer;
}