@layer rdg.MeasuringCell {
  .m1l09lto7-0-0-beta-38 {
    contain: strict;
    grid-row: 1;
    visibility: hidden;
  }
}

@layer rdg.Cell {
  .c1wupbe7-0-0-beta-38 {
    /* max-content does not work with size containment
     * dynamically switching between different containment styles incurs a heavy relayout penalty
     * Chromium bug: at odd zoom levels or subpixel positioning,
     * layout/paint/style containment can make cell borders disappear
     *   https://bugs.chromium.org/p/chromium/issues/detail?id=1326946
     */
    position: relative; /* needed for absolute positioning to work */
    padding-block: 0;
    padding-inline: 24px;
    /* border-inline-end: 1px solid var(--rdg-border-color); */
    /* border-block-end: 1px solid var(--rdg-border-color); */
    border-bottom: 1px solid var(--rdg-border-color);
    grid-row-start: var(--rdg-grid-row-start);
    background-color: inherit;

    display: flex;
    flex-direction: column;
    justify-content: center;

    white-space: nowrap;
    overflow: clip;
    text-align: center;
    /* overflow: hidden; */
    text-overflow: ellipsis;
    outline: none;
  }

  /* .c1wupbe7-0-0-beta-38[aria-selected='true'] {
    outline: 2px solid var(--rdg-selection-color);
    outline-offset: -2px;
  } */
}

@layer rdg.Cell {
  .cd0kgiy7-0-0-beta-38 {
    position: sticky;
    /* Should have a higher value than 0 to show up above unfrozen cells */
    z-index: 1;
  }
}

@layer rdg.Cell {
  .c1730fa47-0-0-beta-38 {
    box-shadow: calc(2px * var(--rdg-sign)) 0 5px -2px rgba(136, 136, 136, 0.3);
  }
}

@layer rdg.CheckboxLabel {
  .c1hs68w07-0-0-beta-38 {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    inset: 0;
    margin-inline-end: 1px; /* align checkbox in row group cell */
  }
}

@layer rdg.CheckboxInput {
  .cojpd0n7-0-0-beta-38 {
    all: unset;
  }
}

@layer rdg.CheckboxIcon {
  .cwsfieb7-0-0-beta-38 {
    content: '';
    inline-size: 20px;
    block-size: 20px;
    border: 2px solid var(--rdg-border-color);
    background-color: var(--rdg-background-color);
  }

  .cojpd0n7-0-0-beta-38:checked + .cwsfieb7-0-0-beta-38 {
    background-color: var(--rdg-checkbox-color);
    outline: 4px solid var(--rdg-background-color);
    outline-offset: -6px;
  }

  .cojpd0n7-0-0-beta-38:focus + .cwsfieb7-0-0-beta-38 {
    border-color: var(--rdg-checkbox-focus-color);
  }
}

@layer rdg.CheckboxLabel {
  .c1fgadbl7-0-0-beta-38 {
    cursor: default;
  }

  .c1fgadbl7-0-0-beta-38 .cwsfieb7-0-0-beta-38 {
    border-color: var(--rdg-checkbox-disabled-border-color);
    background-color: var(--rdg-checkbox-disabled-background-color);
  }
}

@layer rdg.GroupCellContent {
  .g1w3c5217-0-0-beta-38 {
    outline: none;
  }
}

@layer rdg.GroupCellCaret {
  .cm5tyhw7-0-0-beta-38 {
    margin-inline-start: 4px;
    stroke: currentColor;
    stroke-width: 1.5px;
    fill: transparent;
    vertical-align: middle;
  }

  .cm5tyhw7-0-0-beta-38 > path {
    transition: d 0.1s;
  }
}

@layer rdg.DragHandle {
  .cadd3bp7-0-0-beta-38 {
    --rdg-drag-handle-size: 8px;
    z-index: 0;
    cursor: move;
    inline-size: var(--rdg-drag-handle-size);
    block-size: var(--rdg-drag-handle-size);
    background-color: var(--rdg-selection-color);
    place-self: end;
  }

  .cadd3bp7-0-0-beta-38:hover {
    --rdg-drag-handle-size: 16px;
    border: 2px solid var(--rdg-selection-color);
    background-color: var(--rdg-background-color);
  }
}

@layer rdg.DragHandle {
  .ccmuez27-0-0-beta-38 {
    z-index: 1;
    position: sticky;
  }
}

@layer rdg.EditCell {
  .c1tngyp17-0-0-beta-38 {
    padding: 0;
  }
}

@layer rdg.SortableHeaderCell {
  .hizp7y17-0-0-beta-38 {
    display: flex;
  }
}

@layer rdg.SortableHeaderCellName {
  .h14cojrm7-0-0-beta-38 {
    flex-grow: 1;
    overflow: clip;
    text-overflow: ellipsis;
  }
}

@layer rdg.HeaderCell {
  .celq7o97-0-0-beta-38 {
    cursor: pointer;
  }
}

@layer rdg.HeaderCell {
  .ceqw94e7-0-0-beta-38 {
    touch-action: none;
  }
}

@layer rdg.HeaderCell {
  .r12jy2ca7-0-0-beta-38 {
    cursor: col-resize;
    position: absolute;
    inset-block-start: 0;
    inset-inline-end: 0;
    inset-block-end: 0;
    inline-size: 10px;
  }
}

@layer rdg.Row {
  .r1otpg647-0-0-beta-38 {
    display: contents;
    line-height: var(--rdg-row-height);
    background-color: var(--rdg-background-color);
  }

  .r1otpg647-0-0-beta-38:hover {
    background-color: var(--rdg-row-hover-background-color);
  }

  .r1otpg647-0-0-beta-38[aria-selected='true'] {
    background-color: var(--rdg-row-selected-background-color);
  }

  .r1otpg647-0-0-beta-38[aria-selected='true']:hover {
    background-color: var(--rdg-row-selected-hover-background-color);
  }
}

@layer rdg.FocusSink {
  .rel5gk27-0-0-beta-38 {
    outline: 2px solid var(--rdg-selection-color);
    outline-offset: -2px;
  }
}

@layer rdg.FocusSink {
  .r1qymf1z7-0-0-beta-38::before {
    content: '';
    display: inline-block;
    height: 100%;
    position: sticky;
    inset-inline-start: 0;
    border-inline-start: 2px solid var(--rdg-selection-color);
  }
}

@layer rdg.HeaderRow {
  .h197vzie7-0-0-beta-38 {
    display: contents;
    line-height: var(--rdg-header-row-height);
    background-color: var(--rdg-header-background-color);
    text-align: center;
    font-weight: bold;
  }

  .h197vzie7-0-0-beta-38 > .c1wupbe7-0-0-beta-38 {
    /* Should have a higher value than 1 to show up above regular cells and the focus sink */
    z-index: 2;
    position: sticky;
  }

  .h197vzie7-0-0-beta-38 > .cd0kgiy7-0-0-beta-38 {
    z-index: 3;
  }
}

@layer rdg.Cell {
  .ccpfvsn7-0-0-beta-38 {
    background-color: #ccccff;
  }
}

@layer rdg.Cell {
  .c1bmg16t7-0-0-beta-38 {
    background-color: #ccccff;
  }

  .c1bmg16t7-0-0-beta-38.ccpfvsn7-0-0-beta-38 {
    background-color: #9999ff;
  }
}

@layer rdg.SortIcon {
  .a1mygwml7-0-0-beta-38 {
    fill: currentColor;
  }

  .a1mygwml7-0-0-beta-38 > path {
    transition: d 0.1s;
  }
}

@layer rdg {
  @layer Defaults,
      FocusSink,
      CheckboxInput,
      CheckboxIcon,
      CheckboxLabel,
      Cell,
      HeaderCell,
      SummaryCell,
      EditCell,
      Row,
      HeaderRow,
      SummaryRow,
      GroupedRow,
      Root;

  @layer Defaults {
    .r104f42s7-0-0-beta-38 *,
    .r104f42s7-0-0-beta-38 *::before,
    .r104f42s7-0-0-beta-38 *::after {
      box-sizing: inherit;
    }
  }

  @layer Root {
    .r104f42s7-0-0-beta-38 {
      --rdg-color: #172B4D;
      --rdg-border-color: #DCDFE4;
      --rdg-summary-border-color: #aaa;
      --rdg-background-color: #fafbfc;
      --rdg-header-background-color: #f5f4fc;
      --rdg-row-hover-background-color: hsl(0deg 0% 96%);
      --rdg-row-selected-background-color: hsl(207deg 76% 92%);
      --rdg-row-selected-hover-background-color: hsl(207deg 76% 88%);
      --rdg-checkbox-color: hsl(207deg 100% 29%);
      --rdg-checkbox-focus-color: hsl(207deg 100% 69%);
      --rdg-checkbox-disabled-border-color: #ccc;
      --rdg-checkbox-disabled-background-color: #ddd;
      --rdg-selection-color: #66afe9;
      --rdg-font-size: 14px;
      --rdg-font-family: 'Outfit';
      --rdg-line-height: '16px';
      --rdg-header-font-weight: 500;

      display: grid;

      /* color-scheme: var(--rdg-color-scheme, light dark); */

      /* https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context */
      /* We set a stacking context so internal elements don't render on top of external elements. */
      /* size containment is not used as it could break "width: min-content" for example, and the grid would infinitely resize on Chromium browsers */
      contain: content;
      content-visibility: auto;
      block-size: 350px;
      border: 1px solid var(--rdg-border-color);
      box-sizing: border-box;
      overflow: auto;
      background-color: var(--rdg-background-color);
      color: var(--rdg-color);
      font-size: var(--rdg-font-size);
      font-family: var(--rdg-font-family);
      line-height: var(--rdg-line-height);

      /* needed on Firefox to fix scrollbars */
    }
    .r104f42s7-0-0-beta-38::before {
      content: '';
      grid-column: 1/-1;
      grid-row: 1/-1;
    }

    /* .r104f42s7-0-0-beta-38.rdg-dark {
      --rdg-color-scheme: dark;
      --rdg-color: #ddd;
      --rdg-border-color: #444;
      --rdg-summary-border-color: #555;
      --rdg-background-color: hsl(0deg 0% 13%);
      --rdg-header-background-color: hsl(0deg 0% 10.5%);
      --rdg-row-hover-background-color: hsl(0deg 0% 9%);
      --rdg-row-selected-background-color: hsl(207deg 76% 42%);
      --rdg-row-selected-hover-background-color: hsl(207deg 76% 38%);
      --rdg-checkbox-color: hsl(207deg 100% 79%);
      --rdg-checkbox-focus-color: hsl(207deg 100% 89%);
      --rdg-checkbox-disabled-border-color: #000;
      --rdg-checkbox-disabled-background-color: #333;
    }

    .r104f42s7-0-0-beta-38.rdg-light {
      --rdg-color-scheme: light;
    }

    @media (prefers-color-scheme: dark) {
      .r104f42s7-0-0-beta-38:not(.rdg-light) {
        --rdg-color: #ddd;
        --rdg-border-color: #444;
        --rdg-summary-border-color: #555;
        --rdg-background-color: hsl(0deg 0% 13%);
        --rdg-header-background-color: hsl(0deg 0% 10.5%);
        --rdg-row-hover-background-color: hsl(0deg 0% 9%);
        --rdg-row-selected-background-color: hsl(207deg 76% 42%);
        --rdg-row-selected-hover-background-color: hsl(207deg 76% 38%);
        --rdg-checkbox-color: hsl(207deg 100% 79%);
        --rdg-checkbox-focus-color: hsl(207deg 100% 89%);
        --rdg-checkbox-disabled-border-color: #000;
        --rdg-checkbox-disabled-background-color: #333;
      }
    } */
  }
}

@layer rdg.Root {
  .v7ly7s7-0-0-beta-38 {
    user-select: none;
  }

  .v7ly7s7-0-0-beta-38 .r1otpg647-0-0-beta-38 {
    cursor: move;
  }
}

@layer rdg.FocusSink {
  .fc4f4zb7-0-0-beta-38 {
    grid-column: 1/-1;
    pointer-events: none;
    /* Should have a higher value than 1 to show up above regular frozen cells */
    z-index: 1;
  }
}

@layer rdg.FocusSink {
  .fq51q037-0-0-beta-38 {
    /* Should have a higher value than 3 to show up above header and summary rows */
    z-index: 3;
  }
}

@layer rdg.SummaryCell {
  .s1n3hxke7-0-0-beta-38 {
    inset-block-start: var(--rdg-summary-row-top);
    inset-block-end: var(--rdg-summary-row-bottom);
  }
}

@layer rdg.SummaryRow {
  .snfqesz7-0-0-beta-38 {
    line-height: var(--rdg-summary-row-height);
  }

  .snfqesz7-0-0-beta-38 > .c1wupbe7-0-0-beta-38 {
    position: sticky;
  }
}

@layer rdg.SummaryRow {
  .t1jijrjz7-0-0-beta-38 > .c1wupbe7-0-0-beta-38 {
    z-index: 2;
  }

  .t1jijrjz7-0-0-beta-38 > .cd0kgiy7-0-0-beta-38 {
    z-index: 3;
  }
}

@layer rdg.SummaryRow {
  .t14bmecc7-0-0-beta-38 > .c1wupbe7-0-0-beta-38 {
    border-block-end: 2px solid var(--rdg-summary-border-color);
  }
}

@layer rdg.SummaryRow {
  .b1odhhml7-0-0-beta-38 > .c1wupbe7-0-0-beta-38 {
    border-block-start: 2px solid var(--rdg-summary-border-color);
  }
}

@layer rdg.GroupedRow {
  .gyxx7e97-0-0-beta-38:not([aria-selected='true']) {
    background-color: var(--rdg-header-background-color);
  }

  .gyxx7e97-0-0-beta-38
    > .c1wupbe7-0-0-beta-38:not(:last-child):not(.c1730fa47-0-0-beta-38) {
    border-inline-end: none;
  }
}

@layer rdg.TextEditor {
  .tlmcuo07-0-0-beta-38 {
    appearance: none;

    box-sizing: border-box;
    inline-size: 100%;
    block-size: 100%;
    padding-block: 0;
    padding-inline: 6px;
    border: 2px solid #ccc;
    vertical-align: top;
    color: var(--rdg-color);
    background-color: var(--rdg-background-color);

    font-family: inherit;
    font-size: var(--rdg-font-size);
  }

  .tlmcuo07-0-0-beta-38:focus {
    border-color: var(--rdg-selection-color);
    outline: none;
  }

  .tlmcuo07-0-0-beta-38::placeholder {
    color: #999;
    opacity: 1;
  }
}

.rdg::-webkit-scrollbar {
  width: 4px;
  height: 0;
}

.rdg::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.rdg::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
}

/* .fill {
  block-size: 100%;
} */
