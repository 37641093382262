.section_container {
  display: flex;
  flex-direction: column;
  overflow: auto;
  gap: 16px;
  padding: 12px;
  flex: 1;
}

.main_section {
  order: 2;
  border-radius: 6px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  display: flex;
  flex-direction: column;
  flex: 3;
}

.aside_section {
  order: 1;
  background-color: #f5f4fc;
  border-radius: 6px;
  padding: 16px;
  flex: 2;
  gap: 12px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.scroll_bar::-webkit-scrollbar {
  width: 4px;
  height: 2px;
}

.scroll_bar::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.scroll_bar::-webkit-scrollbar-thumb {
  background-color: #6256af;
}

.stepper_content_container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.stepper_action_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f5f4fc;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 16px 20px;
  gap: 16px;
  box-shadow: 0px 0px 1px 0px #091e421f, 0px 0px 8px 0px #091e4229;
  margin-top: auto;
}

@media all and (min-width: 768px) {
  .section_container {
    flex-direction: row;
  }

  .main_section {
    order: 1;
    overflow: auto;
  }

  .aside_section {
    order: 2;
    overflow: auto;
  }

  .stepper_content_container {
    flex: 1;
    overflow: auto;
  }
}
