.scroll_bar::-webkit-scrollbar {
  width: 4px;
  height: 2px;
}

.scroll_bar::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.scroll_bar::-webkit-scrollbar-thumb {
  background-color: #6256af;
}
