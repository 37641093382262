.mainContainer {
	width: 25%;
	margin: 0 auto;
	height: auto;
	text-align: center;
	padding: 2em 0em;
}

.actionsContainer {
	height: inherit;
	border-radius: 12px;
	display: flex;
	align-items: center;
}