.NavItem {
	text-decoration: none;
	color: #505F79;
	border-right: 2px solid transparent;
	display: flex;
	box-sizing: border-box;
	width: 100%;
	flex-direction: row;
	margin: 8px 0px;
	padding: 4px 14px;
	align-items: center;
}

.NavItem.active {
	border-right-color: #4E3BC9;
  font-weight: 600;
}

.NavItem:hover, .NavItem.active {
	background-color: #EAE8F9;
	color: #4E3BC9;
}

.NavItem.disabled,
.NavItem.disabled:hover,
.NavItem.completed {
  background-color: transparent;
  color: #172b4d;
}