.NavItem {
  text-decoration: none;
  color: #505f79;
  border-right: 2px solid transparent;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  flex-direction: row;
  margin: 8px 0px;
  padding: 4px 14px;
  align-items: center;
}

.NavItem.active {
  border-right-color: #4e3bc9;
  font-weight: 600;
}

.NavItem:hover,
.NavItem.active {
  background-color: #eae8f9;
  color: #4e3bc9;
}

.NavItem.disabled,
.NavItem.disabled:hover,
.NavItem.completed {
  background-color: transparent;
  color: #172b4d;
}
