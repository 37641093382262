.NavItem {
	text-align: center;
	text-decoration: none;
	color: #505F79;
	border-left: 2px solid transparent;
	display: flex;
	width: 100%;
	min-height: 70px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.NavItem.active {
	border-left-color: #4E3BC9;
}

.NavItem:hover, .NavItem.active {
	background-color: #F5F4FC;
	color: #4E3BC9;
}